window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
		// Do something when the flow renders
		// Do something when the flow renders
		document.querySelector('[data-variable="variant"]').value = windowConstants.VARIANT_ID;

	}
}